import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'history',

    routes: [{
            path: '/',
            component: () =>
                import ('@/view/layout/Moon-Landing-Page-Layout'),
            children: [
                // {
                //     path: '',
                //     name: 'landing-page',
                //     component: () =>
                //         import ('@/view/pages/moon/MoonLandingPage.vue'),
                //     meta: { title: 'Moon Program - Sign up | برنامج مون - الانضمام' },
                // },
                {
                    path: '',
                    name: 'landing-page',
                    component: () =>
                        import ('@/view/pages/moon/MoonLandingPageNew.vue'),
                    meta: { title: 'Moon Program - Sign up | برنامج مون - الانضمام' },
                },
                {
                    path: 'join',
                    name: 'join',
                    component: () =>
                        import ('@/view/pages/moon/MoonJoinPage.vue'),
                    meta: { title: 'Moon Program - Sign up | برنامج مون - الانضمام' },
                },
                {
                    path: 'terms&conditions',
                    name: 'terms&conditions',
                    component: () =>
                        import ('@/view/pages/moon/terms&condition.vue'),
                    meta: { title: 'Moon Program - Sign up | برنامج مون - الانضمام' },
                },
            ],
        },
        {
            path: "/dashboard",
            name: "dashboard-home",
            component: () =>
                import ("@/view/pages/moon/Dashboard-new-design-dados.vue"),
                // import('@/view/pages/moon/Disable.vue'),
        },
        // {
        //     path: "/disable",
        //     name: "disable",
        //     component: () =>
        //         import ("@/view/pages/moon/Disable.vue"),
        // },
        // {
        //     path: "/dashboard",
        //     component: () => import("@/view/layout/Layout"),
        //     children: [
        //       {
        //         name: "home",
        //         path: "",
        //         component: () => import("@/view/pages/moon/Dashboard.vue")
        //       }
        //     ]
        //   },
        {
            path: '/landing',
            name: 'landing',
            component: () => import('@/view/pages/moon/Landing.vue'),
            meta: { title: 'Moon Program - Landing | برنامج القمر - الهبوط' },
        },
        {
            path: "/dashboard",
            component: () =>
                import ("@/view/pages/moon/Auth"),
                // import('@/view/pages/moon/Disable.vue'),
            children: [{
                    name: "login",
                    path: "login",
                    component: () =>
                        import ("@/view/pages/moon/LoginDados")
                },
                {
                    name: "register",
                    path: "register",
                    component: () =>
                        import ("@/view/pages/moon/Register")
                }
            ]
        },
        {
            path: '*',
            redirect: '/404',
        },
        {
            // the 404 route, when none of the above matches
            path: '/404',
            name: '404',
            component: () =>
                import ('@/view/pages/error/Error-1.vue'),
        },
    ],
});