import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      $_filteration: "Filteration",
    }),
    $_filterationData() {
      return this.$_filteration;
    },
  },
};
